<template>
	<div>
		<router-view v-if="wallet.success && !wallet.data.isDemo" />

		<div v-if="wallet.error || (wallet.success && wallet.data.isDemo)" class="error-container">
			<ErrorCard :error="wallet.error || { message: 'Cannot withdraw from demo wallet' }" />
		</div>
	</div>
</template>
<script setup>
import { onBeforeMount, reactive } from 'vue';
import apiClient from '@/api';
import * as Sentry from '@sentry/vue';
import { useRoute } from 'vue-router/composables';
import ErrorCard from '@/shared/error-card/ErrorCard.vue';

const route = useRoute();

/** the select wallet for the deposit */
const wallet = reactive({
	loading: false,
	success: false,
	error: null,
	data: null,
});

onBeforeMount(async () => {
	wallet.loading = true;

	try {
		wallet.data = await apiClient.fetchFullWallet(route.params.walletId);
		wallet.success = true;
	} catch (e) {
		wallet.error = e;
		Sentry.captureException(e);
	} finally {
		wallet.loading = false;
	}
});
</script>
<style scoped></style>
